import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import FormSignature from '../components/FormSignature';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import SEO from '../components/SEO';

export default function render({data}) {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />

      <Hero channel={data.site.siteMetadata.channel} />

      <main className="main">
        <div className="wrap">
          <p>
            Desde antes da pandemia do coronavírus, o governo Bolsonaro mostrava
            as suas intenções de destruir os direitos da população para
            favorecer os bilionários e poderosos. Porém, foi com a COVID-19 que
            o presidente e sua corja mostrou todas as suas garras genocidas. A
            lista de crimes é enorme para contar: desprezo ao isolamento social,
            propaganda de remédios ineficazes contra a doença, relativização de
            mais de 200 mil mortos, promoção de aglomerações sem máscara, e,
            agora, sabotagem da única saída para a pandemia: a vacinação para
            todas e todos.
          </p>

          <p>
            Já em março, os deputados do Juntos e do PSOL, respondendo às ruas
            que gritavam #ForaBolsonaro, protocolaram um pedido de impeachment
            que foi apoiado por mais de 200 entidades estudantis, e contou com
            mais de 1,5 milhão de assinaturas. De lá pra cá, muitos outros
            pedidos de impeachment foram protocolados. Hoje, estamos convivendo
            com a falta de oxigênio em Manaus, a sabotagem da vacina e a
            irresponsabilidade com o ENEM, tudo ao mesmo tempo, e tudo por
            responsabilidade do governo. Não é possível que o presidente da
            Câmara, Rodrigo Maia, continue virando as costas para a população.
            Exigimos que o impeachment seja pautado imediatamente, por respeito
            à vida e à dignidade das milhões de brasileiras e brasileiros!
          </p>

          <p>
            Maia, desengaveta o impeachment! #ForaBolsonaro
            #ImpeachmentBolsonaroUrgente
          </p>
        </div>
      </main>

      <FormSignature channel={data.site.siteMetadata.channel} />
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        channel
      }
    }
  }
`;
