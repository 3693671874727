import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Button from './Button';
import CountSignatures from './CountSignatures';

import styles from './Hero.module.scss';

const Hero = ({channel}) => (
  <StaticQuery
    query={graphql`
      query {
        bg: file(relativePath: {eq: "hero-bg.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        logo: file(relativePath: {eq: "logo-peticao.png"}) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        juntos: file(relativePath: {eq: "logo-juntos.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className={styles.container}>
        <Img
          className={styles.image}
          fluid={data.bg.childImageSharp.fluid}
          style={{position: false}}
        />
        <div className={styles.overlay}>
          <div className="wrap">
            <Img
              className={styles.juntos}
              fluid={data.juntos.childImageSharp.fluid}
              style={{position: false}}
            />
            <Img
              className={styles.logo}
              fluid={data.logo.childImageSharp.fluid}
            />
            <CountSignatures channel={channel} />
            <p className={styles.buttonContainer}>
              <Button to="#sign">Assine agora</Button>
            </p>
          </div>
        </div>
      </div>
    )}
  />
);

Hero.propTypes = {
  channel: PropTypes.string,
};

export default Hero;
